import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import SearchResults from "../components/SearchResults/index";
import SearchResultsMobile from "../components/SearchResults/indexMobile";
import {
	PageContentWrapper,
	PageContent,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import PageHeader from "../components/Common/PageHeader";
import { colors } from "../assets/styles/vars";
import { Breadcrumb, CustomButton } from "../assets/styles/globalCommon";
import BannerTwoCol from "../components/Common/BannerTwoCol";
import SearchItemCentro from "../components/Common/SearchItemCentro";
import { centroItem } from "../data/centro-example-data";
import { SliderButton } from "@typeform/embed-react";
import SearchItemCentroSkeleton from "../components/Common/SearchItemCentro/SearchItemCentroSkeleton";

const AlertasPage = ({
	items,
	loadingSuccess,
	authResolved,
	loggedIn,
	appRedirect,
}) => {
	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
	}, [authResolved, loggedIn]);

	return (
		<Layout>
			<Seo title="Alertas" />
			<section>
				<PageHeader title="Alertas" />

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<Breadcrumb>
									<Link to="/account">Mi cuenta</Link>
									<a>></a>
									<a>Alertas</a>
								</Breadcrumb>
								{loadingSuccess && items === null ? (
									<>
										<h4>¡Vaya! No tienes ninguna alerta creada.</h4>
										<p>
											Clica en las campanitas 🔔 de las estaciones y
											crea alertas para que te enviemos info con las
											mejores nevadas de la temporada
										</p>
									</>
								) : (
									<p>
										Este es tu listado de alertas, si quieres eliminar
										alguna, solo clica en la campanita nuevamente.
									</p>
								)}
							</div>

							{loadingSuccess ? (
								<div className="pageCol fullCol">
									{items && items.length > 0 ? (
										<>
											<SearchResults
												centros={items}
												showMoreResults={false}
											/>
											<SearchResultsMobile
												centros={items}
												showMoreResults={false}
											/>
										</>
									) : (
										<SearchItemCentro
											item={centroItem}
											isUserAlerta={true}
											disabled={true}
										/>
									)}
								</div>
							) : (
								<div className="pageCol fullCol">
									<SearchItemCentroSkeleton />
								</div>
							)}

							<div className="pageCol leftCol">
								<p>
									Si tienes más duda, recuerda visitar nuestra sección
									de{" "}
									<Link to="/help" target="_blank">
										preguntas frecuentes
									</Link>{" "}
									o envíanos un consulta.
								</p>
								<br />
								<SliderButton id="kr8xczVw">
									<CustomButton className="ghost withUnderline">
										<p>Enviar Consulta</p>
									</CustomButton>
								</SliderButton>
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>

				<BannerTwoCol
					direction="left"
					img="/technology/tech-img-01.jpg"
					title="Crea las alerta y nosotros te avisaremos."
					text="Con nuestros sistema de alertas recibirás un correo electrónico cada vez que las nevadas lleguen a las estaciones que tu hayas elegido.

Recibirás un reporte con los próximos 5 días y los 3 dias previos. El objetivo es que no te pierdas las mejores nevadas de la temporada.

Podrás darte de baja o eliminar las alertas muy fácilmente desde tu cuenta."
				/>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { items, loadingSuccess } = state.alertas;
	return {
		items,
		loadingSuccess,
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		// usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertasPage);
